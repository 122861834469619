export const environment = {
  enableTIIIStateAdminDelete: false,
  production: true,
  disableAdalNonceCheck: false,
  nonceWhitelist: [],
  env: 'ENV_REPLACE_TOKEN-DAFUpXkpyP',
  forgotPwdLink: 'https://portal.acl.gov',
  oktaConfig: {
    issuer: 'https://dhhsacl.okta.com/oauth2/default',
    url: 'https://dhhsacl.okta.com/',
    clientId: '0oa9yir7mxcJqxus1297',
    redirectUri: `${window.location.origin}/jwt`,
    responseMode: 'fragment',
    pkce: false,
    implicitFlowConfig: {
      redirectUri: `${window.location.origin}/jwt`,
      postLogoutRedirectUri: `${window.location.origin}/`,
    },
  }
};
